import React from 'react'
import { connect } from 'react-redux'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import BobFilter from '../BobFilter'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { Button as ButtonBON } from '../../../common/BoatOnButton'

import dictionary from './Dictionary/LogBookListDico'
import LogBookCard from './LogBookCard'

import { getNavigationsFromGroup } from '../../../../actions/bob/navigation.actions'
import { boatActions } from '../../../../actions/boat.actions'
import { getAisByNavigation } from '../../../../actions/bob/navigationApiData.actions'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/LogBookListCss'
import BoatOnLoading from '../../../common/UsefullComponents/BoatOnLoading'
import { IosShare as IosShareIcon } from '@mui/icons-material'
import { getLogbookExcel } from '../../../../actions'

import { getTextFromAddress } from './Utils/eventsFilter'
import LogBookCreateNav from './Modals/LogBookCreateNav'

class LogBookList extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            selectedModal: false,
            usedNavigations: null,
            size: 1,
        }

        this.closeNewNavigation = this.closeNewNavigation.bind(this)
        this.openNewNavigation = this.openNewNavigation.bind(this)
        this.redirectToCard = this.redirectToCard.bind(this)
        this.getMoreNav = this.getMoreNav.bind(this)
        this.downloadExcel = this.downloadExcel.bind(this)
    }

    componentDidMount() {
        const { dispatch, groupId } = this.props

        dispatch(getNavigationsFromGroup(groupId))
        dispatch(boatActions.requestLinkedBoats())
    }

    componentDidUpdate(prevProps, prevState) {
        const { loading, navigations, ais } = this.props
        const { size, usedNavigations } = this.state

        if (
            (loading === 0 && prevProps.loading !== 0) ||
            (navigations &&
                (prevState.size !== size ||
                    !usedNavigations ||
                    navigations?.length !== prevProps.navigations?.length))
        ) {
            const currentNavs = navigations ?? []
            const navigationToStart = currentNavs.filter(
                item =>
                    !item.delimitedDate.endDate &&
                    item.nbDepartingPassengers === null,
            )
            const navigationInProgess = currentNavs.filter(
                item =>
                    item.delimitedDate.startDate &&
                    !item.delimitedDate.endDate &&
                    item.nbDepartingPassengers !== null,
            )
            const navigationDone = currentNavs.filter(
                item =>
                    item.delimitedDate.startDate && item.delimitedDate.endDate,
            )

            const usedNavigations = [
                ...navigationToStart,
                ...navigationInProgess.sort(
                    (a, b) =>
                        b.delimitedDate.startDate - a.delimitedDate.startDate,
                ),
                ...navigationDone.sort(
                    (a, b) =>
                        b.delimitedDate.startDate - a.delimitedDate.startDate,
                ),
            ].slice(0, size * 5)

            this.setState(
                {
                    usedNavigations,
                },
                () => {
                    for (const nav of usedNavigations) {
                        const currentAis = ais.filter(
                            item => item.navigationId === nav.id,
                        )

                        if (nav.isAisUsed && currentAis.length === 0)
                            this.props.dispatch(getAisByNavigation(nav.id))
                    }
                },
            )
        }
    }

    openNewNavigation() {
        this.setState({ selectedModal: 'create' })
    }

    closeNewNavigation() {
        this.setState({ selectedModal: false })
    }

    downloadExcel() {
        const { groupId } = this.props
        this.props.dispatch(getLogbookExcel(this.context, groupId))
    }

    redirectToCard(id) {
        this.historyPush(
            history,
            AppRoute.LogBook.LogBookID,
            '',
            {},
            { id: `${id}` },
        )
    }

    getMoreNav() {
        this.setState({ size: this.state.size + 1 })
    }

    render() {
        const { selectedModal, usedNavigations } = this.state
        const {
            classes,
            navigations,
            loading,
            isMobile,
            bobFilter,
        } = this.props

        let content = (
            <BoatOnLoading description={`${this.displayText('loading')} ...`} />
        )

        let canBeExtend = false

        if (usedNavigations && usedNavigations.length > 0) {
            content = usedNavigations.map(navigation => (
                <LogBookCard
                    key={`logbookcard-${navigation.id}`}
                    navigation={navigation}
                    onClick={id => this.redirectToCard(id)}
                />
            ))

            if (navigations?.length !== usedNavigations?.length)
                canBeExtend = true
        } else if (
            loading === 0 &&
            (bobFilter.selectedCrew?.length > 0 ||
                bobFilter.rangeDate?.start ||
                bobFilter.rangeDate?.end ||
                bobFilter.searchString?.length > 0 ||
                bobFilter.selectedBoat?.length > 0 ||
                bobFilter.selectedBoatsByFleet?.length > 0)
        ) {
            content = (
                <div className={classes.noContent}>
                    {this.displayText('NoNavigationWithFilters')}
                </div>
            )
        } else if (loading === 0) {
            content = (
                <div className={classes.noContent}>
                    {this.displayText('NoNavigation')}
                </div>
            )
        }

        return (
            <div className={classes.root}>
                <div className={classes.filterContainer}>
                    <BobFilter
                        fieldName={'navigations'}
                        activeButton={{
                            rangeDate: true,
                            crew: true,
                            boatsByFloat: true,
                        }}
                        noDivider
                        rightComponent={
                            !isMobile && (
                                <div className={classes.filterExportAdd}>
                                    <div
                                        className={classes.popoverRowRight}
                                        onClick={() => {
                                            this.downloadExcel()
                                        }}
                                    >
                                        <IosShareIcon
                                            style={{
                                                marginTop: 3,
                                                height: 30,
                                                width: 30,
                                            }}
                                            className={classes.travelIcons}
                                        />
                                    </div>
                                    <ButtonBON
                                        style={{ marginLeft: 30 }}
                                        variant="contained"
                                        color="primary"
                                        label={this.displayText(
                                            'newNavigation',
                                        )}
                                        size={`large`}
                                        fullWidth={isMobile}
                                        onClick={this.openNewNavigation}
                                    />
                                </div>
                            )
                        }
                    />
                </div>
                {isMobile && (
                    <div className={classes.newNavigation}>
                        <div
                            className={classes.popoverRow}
                            onClick={this.downloadExcel}
                        >
                            <IosShareIcon className={classes.travelIcons} />
                        </div>
                        <ButtonBON
                            variant="contained"
                            color="primary"
                            label={this.displayText('newNavigation')}
                            size={`large`}
                            fullWidth={isMobile}
                            onClick={this.openNewNavigation}
                        />
                    </div>
                )}

                {content}

                {canBeExtend && (
                    <div className={classes.seeMore} onClick={this.getMoreNav}>
                        {this.displayText('seeMore')}
                    </div>
                )}

                <LogBookCreateNav
                    selectedModal={selectedModal}
                    closeNavigation={this.closeNewNavigation}
                />
            </div>
        )
    }
}

function filterNavigation(navigations, bobFilter, users) {
    if (
        bobFilter.selectedBoatsByFleet &&
        bobFilter.selectedBoatsByFleet.length > 0
    ) {
        navigations = navigations.filter(nav =>
            bobFilter.selectedBoatsByFleet.includes(nav.boatId),
        )
    }

    if (bobFilter.selectedCrew && bobFilter.selectedCrew.length > 0) {
        navigations = navigations.filter(nav => {
            const totalCrew = [
                ...nav.navigationMembers.map(member => member.userLinkId),
                nav.captainLinkId,
            ]
            let keepNav = false

            bobFilter.selectedCrew.forEach(search => {
                if (totalCrew.includes(search)) keepNav = true
            })

            return keepNav
        })
    }

    if (bobFilter.rangeDate.start && bobFilter.rangeDate.end) {
        const start = new Date(bobFilter.rangeDate.start)
        const end = new Date(bobFilter.rangeDate.end)

        navigations = navigations.filter(nav => {
            if (
                nav.delimitedDate.startDate &&
                start < new Date(nav.delimitedDate.startDate) &&
                end > new Date(nav.delimitedDate.startDate)
            )
                return true
            return false
        })
    }

    if (bobFilter.searchString && bobFilter.searchString !== '') {
        const search = bobFilter.searchString.toLowerCase()

        navigations = navigations.filter(nav => {
            const totalCrewIds = [
                ...nav.navigationMembers.map(member => member.userLinkId),
                nav.captainLinkId,
            ]
            const totalCrew = totalCrewIds.map(id => {
                const item = users.find(user => user.id === id)

                if (item?.user)
                    return `${item.user.firstName} ${item.user.lastName}`
                if (item?.userSubscribe) return item?.userSubscribe?.mail
                return ''
            })
            let itemFound = false

            if (nav.comment?.toLowerCase()?.search(search) !== -1) return true

            const departureText = getTextFromAddress(nav.departureAddress)

            if (
                departureText !== '' &&
                departureText.toLowerCase().search(search) !== -1
            )
                return true

            const arrivalText = getTextFromAddress(nav.arrivalAddress)

            if (
                arrivalText !== '' &&
                arrivalText.toLowerCase().search(search) !== -1
            )
                return true

            if (
                nav.boat?.name &&
                nav.boat?.name?.toLowerCase()?.search(search) !== -1
            )
                return true

            if (
                nav.boat?.maker &&
                nav.boat?.maker?.toLowerCase()?.search(search) !== -1
            )
                return true

            if (
                nav.boat?.model &&
                nav.boat?.model?.toLowerCase()?.search(search) !== -1
            )
                return true

            if (
                nav.boat?.serialNumber &&
                nav.boat?.serialNumber?.toLowerCase()?.search(search) !== -1
            )
                return true

            if (
                nav.boat?.description &&
                nav.boat?.description?.toLowerCase()?.search(search) !== -1
            )
                return true

            nav.stopOvers.forEach(stop => {
                const stopAddressText = getTextFromAddress()
                if (
                    stopAddressText !== '' &&
                    stopAddressText.toLowerCase().search(search) !== -1
                )
                    itemFound = true
            })

            totalCrew.forEach(user => {
                if (user.toLowerCase()?.search(search) !== -1) itemFound = true
            })

            if (itemFound === true) return true

            return false
        })
    }

    return navigations
}

function mapStateToProps(state) {
    const isMobile = window.innerWidth < 600
    const bobFilter = state.filter.bobFilter
    const users = state.group?.groupsMembers?.linkRGU
    let navigations = state.logbook?.navigations

    if (navigations) {
        navigations = filterNavigation(navigations, bobFilter, users)
    }

    return {
        isMobile,
        bobFilter,
        navigations: navigations,
        loading: state.logbook.loading,
        groupId: state.group?.currentGroupId,
        events: state.bob.events || [],
        ais: state.navigationApiData.ais || [],
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogBookList))
