export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 40px)',
        padding: '20px',
        paddingTop: '10px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: 0,
            paddingTop: '10px',
        },
    },
    panel: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },

    field: {
        width: '100%',
        margin: 'auto',
        fontSize: '16px',
    },

    line: {
        width: 'calc(100% - 48px)',
        margin: 'auto',
        fontSize: '16px',
    },

    label: {
        width: '200px',
        fontSize: '18px',
        [theme.breakpoints.down('md')]: {
            fontSize: '12px',
            width: '100%',
        },
    },

    buttonAdd: {
        margin: '30px 0',
        padding: '10px',
        width: '500px',
        float: 'right',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            fontSize: '0.9em',
        },
    },

    buttonSvg: {
        fontSize: '30px!important',
    },

    blocs: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },

    blocRed: {
        backgroundColor: '#E36B61',
        marginRight: '10px',
        marginLeft: '10px',
        [theme.breakpoints.down('md')]: {
            marginRight: '0px',
            marginLeft: '0px',
        },
    },

    blocGreen: {
        backgroundColor: '#6BC070',
        marginRight: '10px',
        [theme.breakpoints.down('md')]: {
            marginRight: '0px',
        },
    },

    blocYellow: {
        backgroundColor: '#F4BA5C',
        marginLeft: '10px',
        [theme.breakpoints.down('md')]: {
            marginLeft: '0px',
        },
    },

    bloc: {
        position: 'relative',
        width: 'calc(33% - 20px)',
        height: '120px',
        borderRadius: '5px',
        marginBottom: '1%',
        display: 'flex',
        flexDirection: 'row',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',

        [theme.breakpoints.down('md')]: {
            width: '100%',
            height: '60px',
            marginBottom: '5%',
        },
    },

    icons: {
        width: '105px',
    },

    iconMenu: {
        display: 'inline-block',
        color: '#fff',
        width: '90px',
        height: '90px',
        paddingTop: '15px',
        paddingLeft: '15px',
        [theme.breakpoints.down('md')]: {
            display: 'inline-block',
            color: '#fff',
            width: '40px',
            height: '40px',
            paddingTop: '10px',
            paddingLeft: '10px',
        },
    },

    infoText: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 120px)',
        marginRight: '15px',
        [theme.breakpoints.down('md')]: {
            marginRight: '10px',
        },
    },

    numberStock: {
        display: 'inline-block',
        color: 'white',
        fontSize: '2.7em',
        textAlign: 'right',
        width: '100%',
        height: '50%',
        marginTop: '10px',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.2em',
        },
    },

    textStock: {
        position: 'absolute',
        color: 'white',
        fontSize: '1.4em',
        textAlign: 'right',
        minWidth: '150px',
        marginLeft: '115px',
        right: 15,
        bottom: 15,
        lineHeight: 1,
        [theme.breakpoints.down('md')]: {
            fontSize: '1em',
            right: 10,
            bottom: 10,
        },
    },

    // Fin deuxième ligne avec les fonctions: "Je recherche", "Plus de filtres", "Tout déplier", "Tout sélectionner"

    firstPanel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    panelCategorie: {
        width: 'calc(100% - 70px)',
        display: 'flex',
        marginLeft: '30px',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0',
            width: 'calc(100% - 50px)',
        },
    },
    panelCategorieEquipment: {
        width: 'calc(100% - 30px)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: '30px',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 50px)',
            marginLeft: '0',
        },
    },
    panelCategorieEquipmentNoEvent: {
        width: 'calc(100% - 90px)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginLeft: '90px',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 40px)',
            marginLeft: '40px',
        },
    },

    testCat: {
        display: 'flex',
        position: 'absolute',
        top: '-13px',
        left: '10px',
        width: '100%',
    },

    categorie: {
        [theme.breakpoints.down('md')]: {
            fontSize: '0.8em',
            paddingLeft: 0,
            marginTop: '2px',
        },
    },
    categorieFamily: {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '25px',
    },

    equipementType: {
        width: '228px',
        minWidth: '228px',
        display: 'flex',
        flexDirection: 'column',
        flexBasis: 0,
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 90px)',
            minWidth: 'auto',
        },
    },
    partType: {
        width: '175px',
        minWidth: '175px',
        display: 'flex',
        flexDirection: 'column',
        flexBasis: 0,
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 90px)',
            minWidth: 'auto',
        },
    },

    equipement: {
        marginRight: '20px',
        minWidth: 'calc(50% - 20px)',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
            fontSize: '0.9em',
            width: '100%',
        },
    },

    type: {
        minWidth: '50%',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        fontSize: '12px',
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
            fontSize: '0.7em',
            width: '100%',
        },
    },

    dateEquipement: {
        width: '400px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    rangeDatePerso: {
        width: '120px',
        marginRight: '15px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    detailPanel: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 5px)',
            paddingRight: '5px',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 10px)',
            paddingRight: '10px',
        },
    },

    bell: {
        width: '70px',
        [theme.breakpoints.down('md')]: {
            width: '60px',
        },
    },

    equipementDetails: {
        minWidth: '100%',
        overflowWrap: 'break-word',
    },

    equipementTypeDetail: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            width: '99px',
        },
    },

    equipementDetail: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
            fontSize: '0.8em',
        },
    },

    hideInMobile: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
    },
    hideInTab: {
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    showInMobile: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
        },
    },

    typeEquipement: {
        width: '300px',
        [theme.breakpoints.down('md')]: {
            textAlign: 'left',
            fontSize: '0.7em',
        },
    },

    stocks: {
        width: '250px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    stock: {
        width: '50px',
        [theme.breakpoints.down('sm')]: {
            width: '35px',
        },
    },

    cssDatePart: {
        width: 'calc(100% - 355px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '800px',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-end',
            width: '100%',
            minWidth: '115px',
        },
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'flex-end',
            width: '100%',
            minWidth: '60px',
        },
    },
    cssDateEquipment: {
        width: 'calc(100% - 410px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        maxWidth: '800px',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-end',
            width: '100%',
            minWidth: '60px',
        },
    },

    dateBottom: {
        width: '440px',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    startDate: {
        width: '140px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    endDate: {
        width: '140px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    replacehours: {
        width: '140px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },

    stockDetails: {
        width: '80px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    redStock: {
        backgroundColor: 'red',
        color: 'white',
    },

    dateTopGreen: {
        width: '50px',
        height: '40px',
        backgroundColor: '#6BC070',
        borderRadius: '5px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('md')]: {
            marginTop: '10px',
            width: '25px',
            height: '25px',
        },
    },

    dateTopOrange: {
        width: '30px',
        height: '30px',
        backgroundColor: '#F4BA5C',
        borderRadius: '5px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        marginLeft: '25%',
        [theme.breakpoints.down('md')]: {
            marginTop: '10px',
            width: '25px',
            height: '25px',
        },
    },
    colorOrange: { color: '#F4BA5C' },
    dateAlert: {
        width: '30px',
        height: '30px',
        backgroundColor: '#C4C4C4',
        borderRadius: '5px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('md')]: {
            width: '25px',
            height: '25px',
        },
    },

    dateAlertGreen: {
        backgroundColor: '#6BC070',
    },

    dateAlertOrange: {
        backgroundColor: '#F4BA5C',
    },
    dateAlertRed: {
        backgroundColor: '#E36B61',
    },

    bellIconTop: {
        width: '20px',
        height: '20px',
        marginLeft: '5px',
        marginTop: '5px',
        [theme.breakpoints.down('md')]: {
            width: '15px',
            height: '15px',
            marginBottom: '5px',
        },
    },

    catIconTop: {
        width: '24px',
        height: '24px',
        marginRight: '10px',
        paddingLeft: '10px',
        alignSelf: 'center',
    },

    bellIcon: {
        width: '20px',
        height: '20px',
        marginLeft: '0px',
        marginTop: '5px',
        [theme.breakpoints.down('md')]: {
            width: '15px',
            height: '15px',
            marginBottom: '5px',
        },
    },

    extension: {
        boxShadow: 'none',
        margin: '0px !important',
        '&:before': {
            opacity: 0,
        },
    },

    backgroundBlue: {
        backgroundColor: '#F7F9FB',
    },

    panelSummary: {
        padding: 0,
        borderBottom: 'none',
        '& .Mui-expanded': {
            border: 'none !important',
        },
        border: '1px solid #c4c4c4 !important',
    },
    panelWithStatus: {
        marginLeft: '3px !important',
        borderLeft: 'none !important',
    },
    containerFamily: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        width: '100%',
        margin: 'auto',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        width: 'calc(100% - 100px)',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 86px)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 40px)',
        },
    },
    panelDetail: {
        padding: 5,
        paddingRight: 0,
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
        border: '1px solid #c4c4c4',
    },
    panelDetailBlue: {
        backgroundColor: '#F7F9FB',
    },
    divider: {
        marginTop: '15px',
        marginBottom: '15px',
    },

    dividerH: {
        height: '44px',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },

    moreHoriz: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            display: 'block',
            alignItems: 'center',
            marginTop: '10px',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            alignItems: 'center',
            marginTop: '10px',
            padding: 0,
            minWidth: '25px',
            marginRight: '5px',
        },
    },

    iconsEdit: {
        cursor: 'pointer',
        width: '40px',
        height: '40px',
        padding: '0px 5px',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            width: '30px',
            height: '30px',
        },
    },

    globalIcon: {
        [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0px 10px',
        },
    },

    iconPopover: {
        [theme.breakpoints.down('md')]: {
            marginTop: '20px',
        },
    },
    modalTitle: {
        fontSize: '18px',
        fontWeight: 500,
        textAlign: 'left',
        marginBottom: '10px',
    },
    title: {
        fontWeight: 500,
        marginBottom: '10px',
    },
    espaces: {
        padding: '10px 0px',
    },
    dateType: {
        display: 'flex',
        flexDirection: 'row',
    },
    textAddDate: {
        fontWeight: 500,
    },
    twoTextField: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '15px',
        width: `100%`,
    },
    twoTextFieldDate: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '15px',
        width: `100%`,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: `flex-start`,
        },
    },
    textField: {
        width: '100% ',
    },

    outlinedIcon: {
        width: '15px',
        height: '15px',
    },
    flexBtn: {
        display: 'flex',
        flexDirection: 'row',
    },
    btnHours: {
        width: 'calc(100% - 10px)',
        marginTop: '10px',
        backgroundColor: 'white',
        border: 'none',
        fontFamily: 'roboto',
        color: '#3040a0',
        fontSize: '1em',
        textDecoration: 'underline',
        marginRigth: '10px',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8em',
        },
    },
    validate: {
        display: 'block',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: 0,
        [theme.breakpoints.down('md')]: {
            marginTop: '5px',
        },
    },
    addDoc: {
        border: '1px dashed #c4c4c4',
        marginTop: 10,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    doc: {
        margin: 'auto',
        display: 'block',
        padding: '10px',
        width: '50px',
        height: '50px',
        color: '#C4C4C4',
    },

    menu: {
        width: '350px',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'auto',
        padding: '10px',
        paddingRight: 0,
    },
    button: {
        margin: '10px',
        marginRight: 0,
        padding: '10px',
    },
    icon: {
        marginRight: '50px',
        marginLeft: 'auto',
        '&:hover': {
            cursor: 'pointer',
        },
        width: '60px',
        height: '60px',
        [theme.breakpoints.down('md')]: {
            marginTop: '15px',
            width: '60px',
            height: '60px',
        },
    },
    iconMinus: {
        marginRight: 0,
        marginLeft: 'auto',
    },

    firstPart: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    },
    hidden: {
        display: 'none',
    },

    testModal1: {
        width: '275px',
        display: 'flex',
        flexDirection: 'row',
        marginRight: '50px',
        marginLeft: 'auto',
        padding: '10px',
        paddingRight: 0,
    },
    iconActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: '20px',
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
        },
    },
    expandButton: {
        width: '48px',
        paddingRight: 0,
        paddingLeft: 0,
        marginRight: 0,
        [theme.breakpoints.down('sm')]: {
            width: '30px',
        },
    },
    formLabelRoot: {
        '&$formLabelOutlined': { transform: 'translate(7px, 12px) scale(0.9)' },
    },
    formLabelOutlined: {},
    stockText: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '8px',
        },
    },
    stockBox: {
        marginRight: '10px',
        width: '50px',
        [theme.breakpoints.down('sm')]: {
            width: '35px',
        },
    },
    colorRed: {
        color: '#f44336',
    },
    tooltip: {
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
    normalTooltip: {
        backgroundColor: '#ffffff',
        color: 'black',
        maxWidth: 400,
        border: '1px solid #dadde9',
        fontSize: '10px',
    },
    boxDate: {
        width: `105px`,
        [theme.breakpoints.down('md')]: {
            width: `115px`,
            marginLeft: `5px`,
            marginRight: `5px`,
        },
        [theme.breakpoints.down('xs')]: {
            width: `25px`,
            marginLeft: `5px`,
            marginRight: `5px`,
        },
    },
    enginesInfo: {
        display: 'none',
    },
    helpIcon: {
        marginTop: `-10px`,
        width: `20px`,
        height: `20px`,
        marginRight: `3px`,
        marginLeft: `3px`,
    },
    tooltipHour: {
        backgroundColor: '#3040a0',
        color: 'white',
        maxWidth: 300,
        border: '1px solid #dadde9',
        fontSize: '15px',
    },
    accordionFamily: {
        marginBottom: '20px !important',
        boxShadow: 'none',
        margin: 0,
    },
    backgroundFamily: {
        backgroundColor: 'rgba(156, 184, 227, 0.3)',
        borderRadius: '7px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
        },
    },
    expandedFixRadius: {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    lateLine: {
        boxShadow: `-3px 0 0 0 #F44336`,
    },
    soonOutDatedLine: {
        boxShadow: `-3px 0 0 0 #F4BA5C`,
    },
    okLine: {
        boxShadow: `-3px 0 0 0 #6BC070`,
    },
    removeBottomBorder: {
        borderBottom: 'none !important',
    },
    removeTopBorder: {
        borderTop: 'none !important',
    },
    lastBottomBorder: {
        borderBottomLeftRadius: '7px',
        borderBottomRightRadius: '7px',
    },
    expanded: {
        borderBottom: '1px solid #c4c4c4 !important',
    },
    alignItemsCenterMobile: {
        [theme.breakpoints.down('md')]: {
            height: '100%',
            alignItems: 'center',
        },
    },
    infoIcons: {
        height: '25px',
        width: '25px',
        marginRight: '5px',
        [theme.breakpoints.down('sm')]: {
            height: '16px',
            width: '16px',
            marginRight: '0',
        },
    },
    nearDate: {
        '& input': {
            color: theme.palette.warning.main,
        },
    },
    passedDate: {
        '& input': {
            color: theme.palette.error.main,
        },
    },
    verticalMobile: {
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            gridGap: 'unset',
        },
    },

    amountContainer: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 15,
        flexWrap: 'wrap',
    },
    amountField: {
        flex: 1,
        minWidth: 150,
    },
    taxField: {
        maxWidth: 80,
    },
})
