export default {
    newNavigation: {
        FR: 'Nouvelle navigation',
        EN: 'New navigation',
    },
    loading: {
        FR: 'Chargement',
        EN: 'Loading',
    },
    durationNav: {
        FR: 'Durée de navigation',
        EN: 'Navigation duration',
    },
    distanceNav: {
        FR: 'Distance',
        EN: 'Distance',
    },
    nauticMille: {
        FR: 'M',
        EN: 'Nm',
    },
    averageSpeed: {
        FR: 'Vitesse moyenne',
        EN: 'Average speed',
    },
    maxSpeed: {
        FR: 'Vitesse max',
        EN: 'Max speed',
    },
    departure: {
        FR: 'Départ',
        EN: 'Departure',
    },
    arrival: {
        FR: 'Arrivée',
        EN: 'Arrival',
    },
    seeLogBook: {
        FR: 'Voir le journal de bord',
        EN: 'Go to logbook',
    },
    crew: {
        FR: 'Équipage',
        EN: 'Crew',
    },
    stopovers: {
        FR: 'Escales',
        EN: 'Stopovers',
    },
    weather: {
        FR: 'Météo',
        EN: 'Weather',
    },
    fillingLevels: {
        FR: 'Niveau des réserves',
        EN: 'Filling levels',
    },
    workingHours: {
        FR: 'Heures de marche',
        EN: 'Working hours',
    },
    boatInfos: {
        FR: 'Informations bateau',
        EN: 'Boat infos',
    },
    boatType: {
        FR: 'Type',
        EN: 'Type',
    },
    boatYear: {
        FR: 'Année',
        EN: 'Year',
    },
    boatMaker: {
        FR: 'Marque',
        EN: 'Maker',
    },
    boatModel: {
        FR: 'Modèle',
        EN: 'Model',
    },
    landingStage: {
        FR: 'Panne',
        EN: 'Landing stage',
    },
    pontoon: {
        FR: 'Ponton',
        EN: 'Pontoon',
    },
    serialNumber: {
        FR: 'Immatriculation',
        EN: 'Serial number',
    },
    mmsiNumber: {
        FR: 'MMSI',
        EN: 'MMSI',
    },
    boatAddress: {
        FR: 'Port ou lieu de stockage',
        EN: 'Port or storage location',
    },
    noNavigation: {
        FR: `Aucune navigation n'a été effectuée.`,
        EN: `No navigation was performed.`,
    },
}
