export default theme => ({
    root: {
        '& .leaflet-div-icon': {
            background: 'none',
            border: 'none',
        },
    },
    filterContainer: {
        padding: '10px 0px',
    },

    mapContainer: {
        position: 'relative',
        '& .leaflet-control-zoom': {
            display: 'none',
        },
        '& .leaflet-container': {
            height: 700,
        },
        border: '1px solid #C1C1C1',
    },
    routeIcon: {
        position: 'absolute',
        height: 32,
        width: 32,
        right: 10,
        zIndex: 400,
        backgroundColor: theme.palette.primary.main,
        padding: 4,
        borderRadius: 4,
        border: '1px solid white',
        color: 'white',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    activatedIcon: {
        backgroundColor: '#5FA7F6',
    },
    notClickable: {
        '&:hover': {
            cursor: 'grab',
            pointerEvent: 'unset',
        },
    },

    //modal
    modalMapRoot: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: 400,
        backgroundColor: 'white',
        zIndex: 400,
        '-webkit-box-shadow': '8px 8px 17px 0px rgba(0,0,0,0.39)',
        boxShadow: '8px 8px 17px 0px rgba(0,0,0,0.39)',
        overflowY: 'auto',
    },
    imageContainer: {
        position: 'relative',
        backgroundColor: 'grey',
        height: 150,
        width: '100%',
    },
    modalMapImage: {
        maxWidth: '100%',
        height: 150,
        margin: 'auto',
        display: 'block',
    },
    closeIcon: {
        '&:hover': {
            cursor: 'pointer',
        },
        position: 'absolute',
        right: 10,
        top: 10,
        color: 'white',
    },
    modalBoatName: {
        margin: '10px 20px',
        paddingBottom: 10,
        color: theme.palette.primary.main,
        borderBottom: 'solid 1px #EBEBEB',
        fontWeight: 500,
    },
    noNavigation: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 0 20px 0',
    },
    modalLastNavData: {
        padding: '0 20px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 5,
        marginBottom: 20,
    },
    modalDataItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    modalDataItemData: {
        fontWeight: 500,
        fontSize: 16,
    },
    modalDataItemLabel: {
        fontWeight: 400,
        fontSize: 10,
        color: '#7B7D7E',
    },

    stepper: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    stepperItem: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    stepperDotContainer: {
        width: 50,
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    stepperDot: {
        borderRadius: '100%',
        backgroundColor: '#EBEBEB',
        height: 20,
        width: 20,
        position: 'relative',
    },
    stepperRoute: {
        '&::after': {
            content: "''",
            height: 50,
            position: 'absolute',
            width: 0,
            borderLeft: 'solid 4px #EBEBEB',
            borderRight: 0,
            borderTop: 0,
            borderBottom: 0,
            borderStyle: 'dashed',
            left: 'calc(50% - 2px)',
            top: 'calc(-100% - 20px)',
        },
    },
    stepperData: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    stepperDate: {
        fontSize: 12,
    },
    stepperHours: {
        fontSize: 16,
    },
    stepperAddress: {
        fontSize: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    stepperAddressIcon: {
        color: theme.palette.primary.main,
        height: '15px !important',
        width: '15px !important',
    },
    stepperAddressContainer: {
        color: theme.palette.primary.main,
    },

    buttonLogBook: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '20px 0',
        width: '50%',
    },

    accordion: {
        backgroundColor: 'transparent !important',
        margin: '0 10px 10px 10px !important',
        boxShadow: 'unset !important',
        borderRadius: '0px !important',
        '&::before': {
            backgroundColor: 'transparent !important',
        },
    },
    accordionSummary: {
        paddingTop: 7,
        margin: '0px !important',
        minHeight: '30px !important',
        backgroundColor: '#EAECF5 !important',
        borderRadius: '4px !important',
    },
    accordionDetails: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important',
    },

    crewContainer: {
        display: 'grid',
        gridTemplateColumns:
            'repeat(auto-fill, minmax(calc((360px + 10px)/3), max-content))',
        width: '100%',
        gap: 5,
    },
    crewItem: {
        maxWidth: '100%',
        overflow: 'hidden',
    },

    userBadgeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
        fontSize: 12,
    },
    userBadge: {
        background: '#E5E7F0',
        color: theme.palette.primary.main,
        width: 17,
        height: 17,
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 10,
        overflow: 'hidden',
        minWidth: 17,
        minHeight: 17,
        border: `solid 1px ${theme.palette.primary.main}`,
    },
    captainBadge: {
        color: 'white',
        background: theme.palette.primary.main,
    },
    stopoversContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    stopoversItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    stopoversTitle: {
        color: theme.palette.primary.main,
    },

    weatherContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        width: '100%',
    },
    weatherData: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    weatherIcon: {
        height: 20,
        width: 20,
        marginLeft: 10,
    },

    fLContainer: {
        display: 'grid',
        gap: 5,
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridAutoRows: 100,
        width: '100%',
    },
    flItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        '&:nth-child(2n)::before': {
            content: "''",
            position: 'absolute',
            width: 2,
            height: 80,
            backgroundColor: '#EBEBEB',
            left: -5,
            top: 10,
        },
        '&:nth-child(2n)::after': {
            content: "''",
            position: 'absolute',
            width: 360,
            height: 2,
            backgroundColor: '#EBEBEB',
            bottom: -2,
            right: 0,
        },
    },
    flTitle: {
        display: 'flex',
        flexDirection: 'row',
        fontSize: 12,
    },
    flIconContainer: {
        height: 25,
        width: 25,
        minHeight: 25,
        minWidth: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        marginRight: 5,
    },
    flIcon: {
        height: 20,
        width: 20,
        filter: 'invert(1)',
    },
    flDataContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
    },
    flData: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        alignItems: 'start',
        justifyContent: 'center',
        fontSize: 25,
    },
    flDataMax: {
        fontSize: 15,
        color: 'black',
        fontWeight: 300,
    },

    whContainer: {
        display: 'grid',
        gap: 5,
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridAutoRows: 100,
        width: '100%',
    },
    whItem: {
        paddingTop: 10,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 5,
        '&:nth-child(3n)::before': {
            content: "''",
            position: 'absolute',
            width: 2,
            height: 80,
            backgroundColor: '#EBEBEB',
            left: -5,
            top: 10,
        },
        '&:nth-child(3n + 2)::before': {
            content: "''",
            position: 'absolute',
            width: 2,
            height: 80,
            backgroundColor: '#EBEBEB',
            left: -5,
            top: 10,
        },
        '&:nth-child(3n)::after': {
            content: "''",
            position: 'absolute',
            width: 360,
            height: 2,
            backgroundColor: '#EBEBEB',
            bottom: -2,
            right: 0,
        },
    },
    whIcon: {
        height: 25,
        width: 25,
    },
    whDataContainer: {
        textAlign: 'center',
        fontSize: 14,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    whValue: {
        fontSize: 20,
        fontWeight: 500,
    },

    boatInfosContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        gap: 10,
    },
    boatInfosItem: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        minWidth: 80,
    },
    boatInfosTitle: {
        fontSize: 12,
        color: '#757575',
        whiteSpace: 'nowrap',
    },
    boatInfosValue: {
        fontSize: 14,
    },
})
