export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 40px)',
        padding: '20px',
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            margin: 0,
        },
    },
    subtitle: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: '0px',
        fontWeight: 500,
    },
    noDoc: {
        textAlign: 'center',
        padding: '15px',
        fontSize: '22px',
    },
    line: {
        backgroundColor: 'white',
    },
    normalLine: {
        width: 'calc(100% - 20px)',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '50px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            height: `fit-content`,
        },
    },
    textField: {
        width: '100%',
    },
    twoTextField: {
        display: 'flex',
        flexDirection: 'row',
    },
    blueLine: {
        backgroundColor: '#F7F9FB',
    },
    infos: {
        width: 'calc(100% - 2px)',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #c4c4c4',
        boxShadow: '0px 2px 10px 0.3px #c4c4c4',
    },
    type: {
        textAlign: 'left',
        marginBottom: 'auto',
    },
    containerText: {
        display: `flex`,
        flexGrow: 1,
        alignItems: `center`,
        justifyContent: 'space-between',
    },
    titleAndType: {
        flexGrow: 1,
        padding: `0 15px`,
        display: `flex`,
        justifyContent: `center`,
        flexDirection: `column`,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(60% - 60px)',
        },
    },
    validate: {
        display: 'block',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
        },
    },
    iconButton: {
        marginLeft: 8,
    },
    actionMobile: {
        width: 207,
        height: 44,
        boxSizing: `border-box`,
        fontWeight: 400,
        fontSize: `14px`,
        justifyContent: `flex-start`,
        paddingLeft: 17,
    },
    iconsMobile: {
        minWidth: '90px',
        display: 'flex',
        flexDirection: 'column',
    },
    dates: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: 'center',
        width: 120,
        [theme.breakpoints.down('sm')]: {
            width: `fit-content`,
            marginTop: 5,
        },
    },
    link: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        textDecoration: 'none',
        color: '#000',
        width: '25px',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            //fontSize: '16px',
        },
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    dividerH: {
        border: '0.5px solid #c4c4c4',
        height: '50px',
    },
    icon: {
        width: '40px',
        height: '40px',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
            padding: '5px',
        },
    },
    iconAdd: {
        marginTop: '15px',
        display: 'block',
        marginRight: '50px',
        marginLeft: 'auto',
        '&:hover': {
            cursor: 'pointer',
        },
        width: '80px',
        height: '80px',
        [theme.breakpoints.down('sm')]: {
            width: '60px',
            height: '60px',
        },
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40vw',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            padding: '5px',
            width: '90vw',
        },
    },
    addDoc: {
        border: '1px dashed #c4c4c4',
        marginBottom: '10px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    addDocError: {
        border: '1px dashed #f44336',
        marginBottom: '10px',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '5px',
        },
    },
    textAdd: {
        margin: '10px 0px',
        fontWeight: 500,
    },
    doc: {
        margin: 'auto',
        display: 'block',
        padding: '10px',
        width: '50px',
        height: '50px',
        color: '#C4C4C4',
    },
    docError: {
        margin: 'auto',
        display: 'block',
        padding: '10px',
        width: '50px',
        height: '50px',
        color: '#f44336',
    },
    errorDocument: {
        color: '#f44336',
        fontSize: '0.75rem',
        marginLeft: '10px',
        marginRight: '14px',
        [theme.breakpoints.down('sm')]: {
            margin: '19px',
        },
    },
    allPhotos: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    images: {
        width: '100px',
        height: '100px',
        marginRight: '15px',
        position: 'relative',
    },
    imagesModal: {
        width: '100%',
        maxWidth: 'calc((100 / 23 * 19vw) - 20px)',
        margin: 'auto',
    },
    pictures: {
        margin: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        border: '0.5px solid #C4C4C4',
        position: 'relative',
    },
    importantImage: {
        width: '30rem',
        margin: 'auto',
        height: '100%',
        position: 'relative',
        display: 'inline-block',
    },
    cross: {
        position: 'absolute',
        right: 0,
        top: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        zIndex: 1,
    },
    ifNeeded: {
        padding: '10px 0px',
        fontWeight: 500,
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    input: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            padding: 0,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '4px',
        },
    },
    labelInput: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    marginInput: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    loading: {
        textAlign: 'center',
    },
    field: {
        width: '98%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    recherche: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '15px',
        },
    },
    title: {
        fontWeight: 500,
        marginBottom: 10,
    },
    verticalMobile: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },

    amountContainer: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 15,
        flexWrap: 'wrap',
        marginLeft: 15,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'unset',
        },
    },
    amountField: {
        flex: 1,
        minWidth: 150,
    },
    taxField: {
        maxWidth: 80,
    },
})
