import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/ExpIncPageCss'
import dictionary from './Dictionary/ExpIncPageDico'
import {
    Typography,
    TextField,
    InputAdornment,
    MenuItem,
} from '@material-ui/core'
import {
    updateStateEvent,
    getImageToDisplay,
    deleteFileFromBobEvent,
    addFileToBobEvent,
    putBOBEvents,
    postBOBEvents,
} from '../../../../actions/bob.actions'
import DisplayerImgModal from '../../DisplayerImgModal'
import {
    Add as AddIcon,
    Remove as RemoveIcon,
    AddAPhoto as AddAPhotoIcon,
} from '@material-ui/icons'
import { typesActions } from '../../../../actions/types.actions'
import BoatOnModal from '../../../common/BoatOnModal'
import ModalAmountItCheck from '../../../common/UsefullComponents/ModalAmountItCheck'
import BoatOnDateSelector from '../../../common/UsefullComponents/BoatOnDateSelector'
import { activityActions } from '../../../../actions/activity.actions'
import { commentsActions } from '../../../../actions/bob/comments.actions'
import BoatOnNumberField from '../../../common/UsefullComponents/BoatOnNumberField'

class BudgetModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        const event = props.event
            ? {
                  ...props.event,
                  transaction: {
                      ...props?.event?.transaction,
                      amountIt: props?.event?.transaction?.amountIt?.toString(),
                      amountEt: props?.event?.transaction?.amountEt?.toString(),
                  },
              }
            : null

        this.state = {
            tabIndex: 0,
            events: [],
            event: event || null,
            openModalConnection: true,
            openMenu: false,
            transactionId: null,
            type: '',
            date: '',
            amount: '',
            title: '',
            details: '',
            update: 0,
            edit: false,
            displayNewFiles: { event: [] },
            fileNoUrl: { event: [] },
            deletedFiles: { event: [] },
            error: false,
            checkModalOpen: null,
            loadingImage: false,
            tax: 20,
        }

        this.save = this.save.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.isConfirmedToggler = this.isConfirmedToggler.bind(this)
        this.handleCloseCheckModal = this.handleCloseCheckModal.bind(this)
        this.onChangeTax = this.onChangeTax.bind(this)
        this.onChangeAmount = this.onChangeAmount.bind(this)
    }

    componentDidMount() {
        if (this.state?.event?.id) {
            // Lancement du chargement de l'activité lorsque la modale est montée
            this.props.dispatch(
                activityActions.getBobEventActivity(this.state?.event?.id),
            )
            this.props.dispatch(
                commentsActions.getBobEventComments(this.state?.event?.id),
            )
        }
        if (this.state.event?.transaction) {
            const transaction = this.state.event.transaction

            if (transaction?.amountIt && transaction?.amountEt) {
                const diff = transaction?.amountIt - transaction?.amountEt
                const tax = (diff / transaction?.amountEt) * 100

                this.setState({ tax })
            }
        }
    }

    deleteDocumentNoUp(i) {
        deleteFileFromBobEvent(this, i, {
            deletedFiles: this.state.deletedFiles.event,
            displayNewFiles: this.state.displayNewFiles.event,
            fileNoUrl: this.state.fileNoUrl.event,
            files: this.state.event.files,
        })
    }

    // CheckModal
    isConfirmedToggler() {
        this.setState({ isConfirmed: true })
    }

    handleCloseCheckModal() {
        this.setState({ checkModalOpen: null })
    }

    async save() {
        const { deletedFiles, fileNoUrl, event, isConfirmed } = this.state
        const {
            delimitedDate,
            title,
            description,
            transaction,
            eventType,
        } = event
        const { boat, openAddModal } = this.props

        if (
            title &&
            title !== '' &&
            eventType &&
            eventType.id &&
            delimitedDate &&
            delimitedDate.startDate &&
            transaction &&
            transaction.amountIt &&
            (parseFloat(event.transaction.amountIt) !== 0 || isConfirmed)
        ) {
            if (
                eventType.id === 1 &&
                ((openAddModal === 2 && transaction.amountIt < 0) ||
                    (openAddModal === 1 && transaction.amountIt > 0))
            ) {
                transaction.amountIt = transaction.amountIt * -1
            }
            if (event.id) {
                this.props.dispatch(
                    putBOBEvents(event.id, title, {
                        newFiles: fileNoUrl.event,
                        files: event.files,
                        delimitedDate: delimitedDate,
                        transaction: transaction,
                        description: description,
                        eventTypeId: eventType.id,
                        deletedFiles: deletedFiles.event,
                    }),
                )
            } else {
                this.props.dispatch(
                    postBOBEvents(boat.id, eventType.id, title, {
                        files: fileNoUrl.event,
                        duplicateFiles: event.files,
                        delimitedDate: delimitedDate,
                        transaction: transaction,
                        description: description,
                    }),
                )
            }
            this.props.handleClose()
        } else if (
            title &&
            title !== '' &&
            eventType &&
            eventType.id &&
            delimitedDate &&
            delimitedDate.startDate &&
            transaction &&
            (transaction.amountIt || transaction.amountEt) &&
            (parseFloat(event.transaction.amountIt) === 0 ||
                parseFloat(event.transaction.amountEt) === 0 ||
                !isConfirmed)
        ) {
            // show ModalAmountItCheck
            this.setState({ checkModalOpen: 'inventoryCheck' })
        } else {
            this.setState({ error: true })
        }
    }

    clickDocument() {
        this.refs.fileUploader.click()
    }

    addDocument(event) {
        if (
            event?.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToBobEvent(this, event, {
                    fileNoUrl: this.state.fileNoUrl.event,
                    displayNewFiles: this.state.displayNewFiles.event,
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToBobEvent(this, event, {
                fileNoUrl: this.state.fileNoUrl.event,
                displayNewFiles: this.state.displayNewFiles.event,
            })
        }
    }

    onChangeAmount(type, value) {
        const { openAddModal } = this.props
        const { event, tax } = this.state

        value = value.toString().replace(',', '.')
        const transaction =
            type === 'amountIt'
                ? {
                      amountIt: value,
                      amountEt: parseFloat(value / (1 + tax / 100)).toFixed(2),
                  }
                : {
                      amountIt: parseFloat(value * (1 + tax / 100)).toFixed(2),
                      amountEt: value,
                  }

        updateStateEvent(this, {
            transaction,
            negativeTransaction:
                event?.eventType?.id === 1 && openAddModal === 1,
        })
    }

    onChangeTax(value) {
        const { event } = this.state

        this.setState(
            {
                tax: value,
            },
            () =>
                this.onChangeAmount(
                    'amountEt',
                    event?.transaction?.amountEt ?? '',
                ),
        )
    }

    _body() {
        const { classes, openAddModal } = this.props
        let { eventTypes } = this.props
        const { event, tax } = this.state

        const eventType = typesActions.getEventTypeById(
            eventTypes,
            event.eventType.id,
        )

        if (
            (eventType && eventType.spending && eventType.id !== 1) ||
            openAddModal === 1
        ) {
            eventTypes = eventTypes.filter(eventType => eventType.spending)
        } else if (
            (eventType && eventType.earning && eventType.id !== 1) ||
            openAddModal === 2
        ) {
            eventTypes = eventTypes.filter(eventType => eventType.earning)
        }

        return (
            <>
                <div>
                    <Typography
                        variant={`body1`}
                        style={{ margin: `10px 0px` }}
                    >
                        {eventType
                            ? eventType.earning
                                ? this.displayText('youEditIncome')
                                : this.displayText('youEditExpense')
                            : openAddModal === 1
                            ? this.displayText('youAddExpense')
                            : this.displayText('youAddIncome')}
                    </Typography>
                    <TextField
                        id="type"
                        variant="outlined"
                        label={
                            openAddModal === 1
                                ? this.displayText('choiceTypeExpense')
                                : this.displayText('choiceTypeIncome')
                        }
                        margin="dense"
                        required
                        select
                        error={
                            !(event && event.eventType && event.eventType.id) &&
                            this.state.error
                        }
                        helperText={
                            !(event && event.eventType && event.eventType.id) &&
                            this.state.error
                                ? this.displayText('errorChoiceType')
                                : ''
                        }
                        value={
                            event && event.eventType && event.eventType.id
                                ? event.eventType.id
                                : -1
                        }
                        onChange={e => {
                            if (
                                event.eventType.id === 1 &&
                                e.target.value !== 1 &&
                                event?.transaction?.amountIt < 0
                            ) {
                                updateStateEvent(this, {
                                    transaction: {
                                        amountIt:
                                            event.transaction.amountIt * -1,
                                    },
                                })
                            } else if (
                                e.target.value === 1 &&
                                openAddModal === 1 &&
                                event.eventType.id !== 1 &&
                                event?.transaction?.amountIt > 0
                            ) {
                                updateStateEvent(this, {
                                    transaction: {
                                        amountIt:
                                            event.transaction.amountIt * -1,
                                    },
                                    negativeTransaction: true,
                                })
                            }
                            updateStateEvent(this, {
                                eventTypeId: e.target.value,
                            })
                        }}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input,
                                marginDense: classes.marginInput,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelInput,
                            },
                        }}
                    >
                        {!(event && event.eventType && event.eventType.id) && (
                            <MenuItem disabled value={-1}>
                                <em> {this.displayText(`typeEv`)} </em>
                            </MenuItem>
                        )}
                        {eventTypes.map(type => (
                            <MenuItem key={type.id} value={type.id}>
                                {this.displayTextApi(type.translation)}
                            </MenuItem>
                        ))}
                    </TextField>
                    <div className={classes.twoTextField}>
                        <BoatOnDateSelector
                            required
                            error={
                                this.state.error &&
                                !event?.delimitedDate?.startDate
                            }
                            helperText={
                                this.state.error &&
                                !event?.delimitedDate?.startDate
                                    ? this.displayText('errorDate')
                                    : ''
                            }
                            id={'datePurchase'}
                            label={this.displayText('date')}
                            onChange={value => {
                                updateStateEvent(this, {
                                    delimitedDate: {
                                        startDate: value,
                                    },
                                })
                            }}
                            value={
                                event.delimitedDate &&
                                event.delimitedDate.startDate
                                    ? new Date(event.delimitedDate.startDate)
                                    : null
                            }
                            disabled={event?.delimitedDate === null}
                        />
                        <div className={classes.amountContainer}>
                            <BoatOnNumberField
                                isDecimal
                                id="amountEt"
                                className={classes.amountField}
                                variant="outlined"
                                label={this.displayText('amountEt')}
                                margin="dense"
                                required
                                error={
                                    this.state.error &&
                                    !(
                                        event.transaction &&
                                        event.transaction.amountEt
                                    )
                                }
                                helperText={
                                    this.state.error &&
                                    !(
                                        event.transaction &&
                                        event.transaction.amountEt
                                    )
                                        ? this.displayText('errorAmount')
                                        : ''
                                }
                                value={event?.transaction?.amountEt}
                                onChange={e =>
                                    this.onChangeAmount(
                                        'amountEt',
                                        e.target.value,
                                    )
                                }
                                onBlur={e => {
                                    if (e.target.value === '')
                                        this.onChangeAmount('amountEt', 0)
                                }}
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                        marginDense: classes.marginInput,
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {eventType &&
                                            eventType.spending !==
                                                eventType.earning ? (
                                                eventType.spending ? (
                                                    <RemoveIcon fontSize="small" />
                                                ) : (
                                                    <AddIcon fontSize="small" />
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            €
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelInput,
                                    },
                                }}
                            />
                            <BoatOnNumberField
                                isDecimal
                                variant="outlined"
                                label={this.displayText('tax')}
                                margin="dense"
                                className={classes.taxField}
                                required
                                error={
                                    this.state.error &&
                                    !(
                                        event.transaction &&
                                        (event.transaction.amountIt ||
                                            event.transaction.amountEt)
                                    )
                                }
                                value={tax}
                                min={0}
                                onChange={e => this.onChangeTax(e.target.value)}
                                InputProps={{
                                    classes: {
                                        input: classes.innput,
                                        marginDense: classes.marginInput,
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            %
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    classes: { root: classes.labelInput },
                                }}
                            />
                            <BoatOnNumberField
                                isDecimal
                                id="amountIt"
                                variant="outlined"
                                label={this.displayText('amountIt')}
                                margin="dense"
                                className={classes.amountField}
                                required
                                error={
                                    this.state.error &&
                                    !(
                                        event.transaction &&
                                        event.transaction.amountIt
                                    )
                                }
                                helperText={
                                    this.state.error &&
                                    !(
                                        event.transaction &&
                                        event.transaction.amountIt
                                    )
                                        ? this.displayText('errorAmount')
                                        : ''
                                }
                                value={event?.transaction?.amountIt}
                                onChange={e =>
                                    this.onChangeAmount(
                                        'amountIt',
                                        e.target.value,
                                    )
                                }
                                onBlur={e => {
                                    if (e.target.value === '')
                                        this.onChangeAmount('amountIt', 0)
                                }}
                                InputProps={{
                                    classes: {
                                        input: classes.input,
                                        marginDense: classes.marginInput,
                                    },
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {eventType &&
                                            eventType.spending !==
                                                eventType.earning ? (
                                                eventType.spending ? (
                                                    <RemoveIcon fontSize="small" />
                                                ) : (
                                                    <AddIcon fontSize="small" />
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            €
                                        </InputAdornment>
                                    ),
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: classes.labelInput,
                                    },
                                }}
                            />
                        </div>
                    </div>
                    {(!this.state?.event?.id ||
                        (this.state?.event?.id &&
                            !this.state.event?.detail &&
                            !this.state.event?.checkup)) && (
                        <TextField
                            id="title"
                            variant="outlined"
                            label={
                                openAddModal === 1
                                    ? this.displayText('titleExpense')
                                    : this.displayText('titleIncome')
                            }
                            margin="dense"
                            required
                            error={!(event && event.title) && this.state.error}
                            helperText={
                                !(event && event.title) && this.state.error
                                    ? this.displayText('errorTitle')
                                    : ''
                            }
                            value={event.title || ''}
                            onChange={e => {
                                updateStateEvent(this, {
                                    title: e.target.value,
                                })
                            }}
                            className={classes.textField}
                            InputProps={{
                                classes: {
                                    input: classes.input,
                                    marginDense: classes.marginInput,
                                },
                            }}
                            InputLabelProps={{
                                shrink: true,
                                classes: {
                                    root: classes.labelInput,
                                },
                            }}
                        />
                    )}
                    <TextField
                        id="details"
                        variant="outlined"
                        label={
                            openAddModal === 1
                                ? this.displayText('detailsExpense')
                                : this.displayText('detailsIncome')
                        }
                        margin="dense"
                        multiline
                        rows={3}
                        value={event.description || ''}
                        onChange={e => {
                            updateStateEvent(this, {
                                description: e.target.value,
                            })
                        }}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input,
                                marginDense: classes.marginInput,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelInput,
                            },
                        }}
                    />
                    <Typography className={classes.textAdd}>
                        {openAddModal === 1
                            ? this.displayText('addDocumentExpense')
                            : this.displayText('addDocumentIncome')}
                    </Typography>
                    <div
                        className={classes.addDoc}
                        onClick={this.clickDocument}
                    >
                        <AddAPhotoIcon className={classes.doc} />
                    </div>
                    <input
                        onChange={this.addDocument}
                        multiple
                        type="file"
                        id="file"
                        ref="fileUploader"
                        style={{ display: 'none' }}
                    />
                    <DisplayerImgModal
                        file={getImageToDisplay(
                            event,
                            this.state.displayNewFiles.event,
                        )}
                        deleteDocumentNoUp={this.deleteDocumentNoUp}
                        loadingImage={this.state.loadingImage}
                    />
                </div>
            </>
        )
    }

    render() {
        const { title, activity, noCross = true, showFollowButton } = this.props
        const { checkModalOpen, event, tabIndex } = this.state

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    [
                        this.displayText('essentialInfos'),
                        event?.id ? this.displayText('activity') : undefined,
                    ],
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    bodies: [
                        this._body(),
                        event?.id
                            ? this._renderActivity(
                                  activity,
                                  event,
                                  'transaction',
                              )
                            : undefined,
                    ],
                })}
                {/* Si on est en mode édition et qu'on est sur le fil d'activité, alors pas de boutons */}
                {this._renderActions(
                    event?.id && tabIndex === 1
                        ? []
                        : [
                              {
                                  action: this.save,
                                  label: this.displayText('validate'),
                              },
                          ],
                )}
                <BoatOnModal
                    openCondition={checkModalOpen}
                    handleClose={this.handleCloseCheckModal}
                    maxWidth={{ inventoryCheck: `sm` }}
                    modalCores={{
                        inventoryCheck: (
                            <ModalAmountItCheck
                                isConfirmedToggler={this.isConfirmedToggler}
                                save={this.save}
                                handleCloseCheckModal={
                                    this.handleCloseCheckModal
                                }
                            />
                        ),
                    }}
                    titles={{
                        inventoryCheck: `Confirmer`,
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        checkups: state.bob.checkups,
        checkupTypes: state.fetch.checkupTypes,
        boat: state.bob.boat,
        bob: state.bob,
        activity: state.activity.activity,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BudgetModal))
