/**
 * Return all the used equiments that have a level to fill and the events
 * linked with the values
 *
 * @function getFillingLevels
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {[BobEvent]} events - all bob events.
 * @param {[UserEquipments]} userEquipments - all equipments types from the user.
 *
 * @typedef {Object} FillingLevels
 * @property {[BobEvent]} bobEventUsed - all bob events used
 * @property {[UserEquipments]} userEquipmentsUsed - all equipments types used
 *
 * @returns {FillingLevels}
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 13/06/2024 - 14:35
 * @author Samuel.C
 */
export function getFillingLevels(events, userEquipments) {
    const userEquipmentsUsed = userEquipments.filter(
        equipment =>
            equipment?.equipmentType?.optionnalDetailActivated?.capacity &&
            equipment?.equipmentType?.optionnalDetailActivated?.fillingLevel,
    )

    const userEquipmentsUsedID = userEquipmentsUsed.map(
        equipment => equipment.equipmentType.id,
    )

    const bobEventUsed =
        events?.filter(
            event =>
                event?.detail?.equipment &&
                event?.delimitedDate?.startDate &&
                !event?.detail?.archived &&
                userEquipmentsUsedID.includes(
                    event.detail?.equipment?.equipmentType?.id,
                ),
        ) || []

    return {
        userEquipmentsUsed,
        bobEventUsed,
    }
}

/**
 * Return all the used equiments that have a level to fill and the events
 * linked with the values
 *
 * @function getWorkingHours
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {[BobEvent]} events - all bob events.
 *
 * @typedef {Object} WorkingHours
 * @property {[BobEvent]} equipmentInUse - all bob events used
 *
 * @returns {WorkingHours}
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 13/06/2024 - 14:35
 * @author Samuel.C
 */
export function getWorkingHours(events) {
    if (!events) return null

    const equipmentInUse = events.filter(
        event =>
            event?.detail?.equipment &&
            event?.delimitedDate?.startDate &&
            !event?.detail?.archived &&
            (event?.detail?.hourReplacement !== null ||
                event?.detail?.hourActual !== null),
    )

    return {
        equipmentInUse,
    }
}

/**
 * Return text from address with data available
 *
 * @function getTextFromAddress
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {[Address]} address - object with all data of address.
 *
 * @returns {String}
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 05/08/2024 - 15:27
 * @author Samuel.C
 */

export function getTextFromAddress(address) {
    let addressText = ''

    if (address?.city) addressText = address.city
    else if (address?.fullText && address.fullText.length <= 30)
        addressText = address.fullText
    else if (address?.latitude && address?.longitude)
        addressText = `${address.latitude}, ${address.longitude}`

    return addressText
}

/**
 * return time in text of the navigation
 *
 * @function getNavigationDurationTime
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Navigation} navigation - navigation.
 *
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 23/08/2024 - 16:16
 * @author Samuel.C
 */
export function getNavigationDurationTime(navigation) {
    if (!navigation) return '00h00'

    if (
        navigation.nbBoardingPassengers === null ||
        !navigation.delimitedDate.startDate
    )
        return '00h00'

    let totalMinutes = 0
    let start = new Date(navigation.delimitedDate?.startDate)
    let end = null

    navigation.stopOvers.forEach(stop => {
        end = new Date(stop.delimitedDate?.startDate)

        if (start) {
            totalMinutes += (end.getTime() - start.getTime()) / 1000 / 60

            start = null
            end = null
        }

        if (stop.delimitedDate?.endDate)
            start = new Date(stop.delimitedDate?.endDate)
        else start = new Date(stop.delimitedDate?.startDate)
    })

    if (start && navigation.delimitedDate?.endDate) {
        end = new Date(navigation.delimitedDate?.endDate)
        totalMinutes += (end.getTime() - start.getTime()) / 1000 / 60
    }

    const h = Math.trunc(totalMinutes / 60)
    const min = Math.trunc(totalMinutes % 60)

    return `${h < 10 ? '0' : ''}${h}h${min < 10 ? '0' : ''}${min}`
}
